import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const ThemeContext = createContext({ theme: "light", toggleTheme: () => {} });

type THEMETYPE = "dark" | "light";

export function ThemeContextProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<THEMETYPE>((localStorage.getItem("theme") as THEMETYPE) ?? "light");

  const toggleTheme = () => {
    const newValue = theme === "light" ? "dark" : "light";
    setTheme(newValue);
    localStorage.setItem("theme", newValue);
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
}
