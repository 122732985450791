import React from "react";
import "./src/styles/global.css";

import { ThemeContextProvider } from "./src/context/ThemeContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./src/config/ApolloClient";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ThemeContextProvider>{element}</ThemeContextProvider>
  </ApolloProvider>
);
